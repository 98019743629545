<template>
  <div>
    <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules">
        <!--        <a-form-model-item label="展会名称" prop="expoId">
                  <a-select
                    v-model="form.expoId"
                    style="width: 100%"
                    placeholder="请选择展会"
                    :options="listExpoSc"
                    @change="selectinitExpoScriptOptionsChange"
                  ></a-select>
                </a-form-model-item>-->
        <a-form-model-item label="绑定展会" prop="expoId" v-if="true">
          <div @click="handleInfo1">
            {{ form.expoName }}
            <a-button type="dashed" @click="handleInfo1" danger><span
              style="color: dodgerblue"> 绑定展会</span>
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item label="绑定剧本" prop="scriptId" v-if="true">
          <div @click="handleInfo">
            {{ form.scriptName }}
            <a-button type="dashed" @click="handleInfo" danger><span
              style="color: dodgerblue"> 绑定剧本</span>
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item label="标签" prop="label">
          <a-select style="width: 100%" v-model="form.label" placeholder="请输入是标签">
            <a-select-option v-for="(item, index) in this.customDict.ExpoLableEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="期待原因" prop="reason">
          <a-input v-model="form.reason" placeholder="请输入内容" type="textarea" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="是否推荐" prop="isRecommend">
          <a-switch v-model="form.isRecommend" />{{ form.isRecommend ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="是否置顶" prop="isTop">
          <a-switch v-model="form.isTop" />{{ form.isTop ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="打call值" prop="callValue">
          <a-input v-model="form.callValue" placeholder="请输入打call值" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="虚拟打call值" prop="callValueVirtual">
          <a-input v-model="form.callValueVirtual" placeholder="请输入虚拟打call值"/>
        </a-form-model-item>
        <!--      <a-form-model-item label="逻辑删除标记 1:删除,0:未删除" prop="isDeleted" v-if="formType === 1">
                <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记 1:删除,0:未删除" />
              </a-form-model-item>-->
        <!--      <a-form-model-item label="备注" prop="remark" >
                <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
              </a-form-model-item>-->
        <!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
                <a-input v-model="form.version" placeholder="请输入乐观锁" />
              </a-form-model-item>-->
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
    <index-script-modal
      ref="IndexScriptModal"
      @select="indexScriptModalSelect"
      @selectName="indexScriptModalName">
    </index-script-modal>
    <index-expo-modal
      ref="IndexExpoModal"
      @select="indexExpoModalSelect"
      @selectname="indexExpoModalName"
    ></index-expo-modal>
  </div>
</template>

<script>
import { getExpoScript, addExpoScript, updateExpoScript, listExpoSc } from '@/api/expo/expoScript'
import IndexScriptModal from '@/components/script/IndexScriptModal'
import IndexExpoModal from '@/views/expo/expoScript/modules/indexExpoModal'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    IndexScriptModal,
    IndexExpoModal
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      listExpoSc: [],
      // 表单参数
      form: {
        id: null,
        expoId: null,
        isTop: null,
        expoName: null,
        scriptName: null,
        scriptId: null,
        label: null,
        reason: null,
        isRecommend: null,
        callValue: null,
        callValueVirtual: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        expoId: [
          { required: true, message: '展会id 外键 expo_expo.id不能为空', trigger: 'blur' }
        ],
        scriptId: [
          { required: true, message: '剧本id 外键script_script.id不能为空', trigger: 'blur' }
        ],
        label: [
          { required: true, message: '标签 1:主办,2:值得期待不能为空', trigger: 'blur' }
        ],
        isRecommend: [
          { required: true, message: '是否推荐 1:是,0:否不能为空', trigger: 'blur' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
    this.initExpoScriptOptions()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        expoId: null,
        isTop: null,
        expoName: null,
        scriptName: null,
        scriptId: null,
        label: null,
        reason: null,
        isRecommend: null,
        callValue: null,
        callValueVirtual: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },

    handleInfo: function(id) {
      this.$refs.IndexScriptModal.open()
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    indexScriptModalSelect(ids) {
      this.form.scriptId = ids
      console.log('获取到的ids', ids)
    },
    indexScriptModalName(scriptName) {
      this.$set(this.form, 'scriptName', scriptName)
      console.log('获取到的scriptName', scriptName, '123', this.form)
    },

    handleInfo1(id) {
      console.log('123', this.$refs.IndexExpoModal)
      this.$refs.IndexExpoModal.open()

      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    indexExpoModalSelect(ids) {
      this.form.expoId = ids
      console.log('获取到的ids', ids)
    },
    indexExpoModalName(expoName) {
      this.$set(this.form, 'expoName', expoName)
      console.log('获取到的expoName', expoName, '123', this.form)
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getExpoScript(id).then(response => {
        Object.assign(this.form, response.data)
        this.open = true
        this.formTitle = '修改'
      })
    },
    initExpoScriptOptions() {
      listExpoSc().then(res => {
        console.log(res.data)
        this.listExpoSc = res.data
      })
    },
    selectinitExpoScriptOptionsChange(value) {
      this.form.expoId = value
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateExpoScript(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addExpoScript(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
